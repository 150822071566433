<template>
  <div id="container" ref="editorContainer" style="height: fit-content; min-height: 100px; overflow: hidden"></div>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, watch} from 'vue';
import * as monaco from 'monaco-editor';

const editorContainer = ref(null);

const props = defineProps({
  code: Object
});

const updateEditorHeight = (editor) => {
  const contentHeight = editor.getContentHeight();
  const editorElement = editor.getDomNode();

  if (editorElement) {
    editorElement.style.height = `${contentHeight + 50}px`;
    editor.layout();
  }
}

const createEditor = () => {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemas: [],
    allowComments: false,
    trailingCommas: 'error'
  });

  const modelUri = monaco.Uri.parse("json://grid/settings.json");
  const jsonModel = monaco.editor.createModel(
      JSON.stringify(props.code, null, '\t'),
      "json",
      modelUri
  );

  if (editorContainer.value) {
    const editor = monaco.editor.create(editorContainer.value, {
      model: jsonModel,
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      automaticLayout: true,
      scrollbar: {
        vertical: 'hidden',
        verticalScrollbarSize: 0,
        horizontal: 'auto',
        horizontalScrollbarSize: 0,
        handleMouseWheel:false,
      }
    });

    updateEditorHeight(editor);
    editor.onDidChangeModelContent(() => {
      updateEditorHeight(editor);
    });
  }
}


const updateEditor = () => {
  const modelUri = monaco.Uri.parse("json://grid/settings.json");
  const jsonModel = monaco.editor.getModel(modelUri);
  jsonModel.setValue(JSON.stringify(props.code, null, '\t'));
}

onMounted(() => {
  createEditor();
});

onBeforeUnmount(() => {
  monaco.editor.getModels().forEach(model => model.dispose());
});

watch(() => props.code, () => {
  updateEditor();
});

function getEditorContent() {
  const modelUri = monaco.Uri.parse("json://grid/settings.json");
  const jsonModel = monaco.editor.getModel(modelUri);
  return jsonModel.getValue();
}


// Expose the method
defineExpose({ getEditorContent });
</script>

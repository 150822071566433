<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div class="row justify-content-center config-edit-form">
      <div class="col-5 mt-3">
        <div class="row mb-4 flex justify-content-center">
          <div class="col-6 mb-4">
            <label for="configurationSelect" class="form-label">Select configuration</label>
            <select
              @change="loadVersions(selectedConfiguration)"
              v-model="selectedConfiguration"
              id="configurationSelect"
              class="form-select"
              aria-label="Configuration selector">
              <option v-for="configuration in configurations" :key="configuration.id" :value="configuration">{{ configuration.name }}</option>
            </select>
          </div>
          <div class="col-6 mb-4">
            <label for="versionSelect" class="form-label">Version</label>
            <select
              @change="loadEnvironments(selectedVersion)"
              v-model="selectedVersion"
              id="versionSelect"
              class="form-select"
              aria-label="Version selector">
              <option v-for="version in versions" :key="version.id" :value="version">{{ version.name }}</option>
            </select>
          </div>
          <div class="mb-4" v-if="selectedVersion">
            <label for="environmentSelect" class="form-label">Environment</label>
            <select
              @change="loadConfig(selectedEnvironment)"
              v-model="selectedEnvironment"
              id="environmentSelect"
              class="form-select"
              aria-label="Environment selector">
              <option v-for="environment in environments" :key="environment.id" :value="environment">{{ environment.name }}</option>
            </select>
          </div>
          <div v-if="selectedEnvironment && !isLoading">
            <div class="col-12">
              <button
                @click="saveConfig()"
                type="button"
                class="btn btn-primary"
                :disabled="versions.length <= 0 || versions === ''">
                Save for selected environment
              </button>
            </div>
            <div class="col-12 mt-2">
              <button
                @click="saveConfig('all')"
                type="button"
                class="btn btn-primary"
                :disabled="versions.length <= 0 || versions === ''">
                Save for all environments
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-11" v-if="selectedEnvironment && !isLoading">
        <MonacoEditor ref="monacoEditor" :code="config" />
      </div>
    </div>
  </div>
</template>

<script>
import MonacoEditor from '@/components/MonacoEditor.vue'
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'EditConfig',
  components: {
    MonacoEditor,
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: false,
      config: {},
      version: '',
      versions: [],
      configuration: '',
      configurations: [],
      environments: [],
      selectedVersion: null,
      selectedLevel: null,
      selectedEnvironment: null,
    }
  },
  methods: {
    async loadConfigurations() {
      try {
        const response = await axios.get('/configurations');
        this.configurations = response.data.configurations;
      } catch (error) {
        console.error('Error fetching configurations:', error);
      }
    },
    async loadVersions(configuration) {
      // Reset versions and environments
      this.versions = [];
      this.environments = [];
      this.selectedEnvironment = null;
      this.selectedVersion = null;

      if (!this.selectedConfiguration) {
        this.versions = [];
        return;
      }
      try {
        const response = await axios.get('/versions?configuration=' + configuration.id);
        this.versions = response.data.versions;
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    },
    async loadEnvironments(version) {
      if (!this.selectedVersion) {
        this.environments = [];
        return;
      }
      try {
        const response = await axios.get('/environments?version=' + version.id);
        this.environments = response.data.environments;
      } catch (error) {
        console.error('Error fetching environments:', error);
      }
    },
    async loadConfig(environment) {
      this.isLoading = true;

      if (!this.selectedEnvironment) {
        this.config = {};
        return;
      }
      try {
        const response = await axios.get('/manage?version=' + environment.version + '&environment=' + environment.id);
        this.config = response.data.config;
        this.old_config = response.data.config;
      } catch (error) {
        console.error('Error fetching config:', error);
      }

      this.isLoading = false;
    },
    async saveConfig(environment=null) {
      // Access the Monaco Editor content
      const editorContent = this.$refs.monacoEditor.getEditorContent();
      this.config_data_after_changes = JSON.parse(editorContent);

      let selected_environment = this.selectedEnvironment.id;

      if (environment === 'all') {
        selected_environment = 'all';
      }

      const data = {
        version_id: this.selectedVersion.id,
        environment_id: selected_environment,
        config: this.config_data_after_changes,
        old_config: this.old_config
      };

      axios.put('/manage', data)
        .then((response) => {
          if (response.status === 200) {
            alert('Config saved successfully');
          } else {
            alert('Error saving config');
          }
        })
        .catch((error) => {
          console.error('Error saving config:', error);
          alert('Error saving config');
        });
    }
  },
  async beforeMount() {
    this.isLoading = true;
    await this.loadConfigurations();
    this.isLoading = false;
  }
}
</script>

<style scoped>
</style>

<template>
    <div>
      <Navbar />
      <loading
          id="loader"
          class="vl-parent"
          v-model:active="isLoading"
          :is-full-page="true"
          color="#007bff"
          :height=128
          :width=128
      />
        <div class="row justify-content-center">
            <div class="col-5 mt-5">
                <div class="mb-4">
                    <label for="addNewConfiguration" class="form-label">Add new configuration:</label>
                    <input type="email" class="form-control" id="addNewConfiguration" v-model="newConfigurationName">
                </div>
                <div class="col-12 mt-1 text-center">
                    <button @click="addNewConfiguration()" class="btn btn-primary" :disabled="!newConfigurationName">Create</button>
                </div>
            </div>
    </div>
    </div>
  </template>
  
  <script>
  import Navbar from "@/components/Navbar.vue";
  import axios from '@/services/axios-config.js';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  
  export default {
    name: 'AddConfiguration',
    components: {
      Navbar,
      Loading
    },
    data() {
        return {
            newConfigurationName: '',
        };
    },
    methods: {
        addNewConfiguration() {
            const data = {
                configuration: this.newConfigurationName,
            };

            axios.post('/configurations', data)
                .then((response) => {
                    if (response.status === 201) {
                        alert('Configuration added');
                        window.location.href = '/';
                    } else {
                        alert('Error adding configuration');
                    }
                })
                .catch((error) => {
                    console.error('Error adding configuration:', error);
                    alert('Error adding configuration');
                });
        },
    },
  }
  </script>
  <style scoped>
  </style>
  
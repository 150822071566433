<template>
  <div>
    <Navbar />
    <loading
        id="loader"
        class="vl-parent"
        v-model:active="isLoading"
        :is-full-page="true"
        color="#007bff"
        :height=128
        :width=128
    />
    <div class="row justify-content-center config-create-form">
      <div class="col-5 mt-5">
        <div class="mb-4">
          <label for="configurationSelect" class="form-label">Select configuration</label>
          <select
              @change="loadVersions(selectedConfiguration)"
              v-model="selectedConfiguration"
              id="configurationSelect"
              class="form-select"
              aria-label="Configuration selector">
              <option v-for="configuration in configurations" :key="configuration.id" :value="configuration">{{ configuration.name }}</option>
          </select>
        </div>
        <div v-show="versions.length > 0" class="mt-4 mb-4">
            <label for="existedVersionsList" class="form-label">Versions list:</label>
            <ul id="existedVersionsList" class="list-group">
                <li v-for="version in versions" :key="version.id" :value="version" class="list-group-item">{{ version.name }}</li>
            </ul>
            <hr class="my-4">
        </div>
        <div v-show="selectedConfiguration && selectedConfiguration.name" class="mb-4">
            <label for="createNewVersion" class="form-label">Create new version:</label>
            <input type="email" class="form-control" id="createNewVersion" aria-describedby="emailHelp" v-model="newVersion">
            <div id="emailHelp" class="form-text">Use only digits and dots. Example: 3.2.1</div>
        </div>
        </div>
        <div class="col-11 mt-5" v-if="newVersion">
          <div class="col-12 mt-5 text-center">
            <button @click="addVersion()" class="btn btn-primary">Create</button>
          </div>
          <div class="mx-auto text-center mt-5"><label for="json-editor" class="form-label text-center mx-auto">Configuration editor:</label></div>
          <MonacoEditor ref="monacoEditor" :code="config" />
        </div>
    </div>
  </div>
</template>

<script>
import MonacoEditor from '@/components/MonacoEditor.vue'
import Navbar from "@/components/Navbar.vue";
import axios from '@/services/axios-config.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'AddVersion',
  components: {
    MonacoEditor,
    Navbar,
    Loading
  },
  data() {
    return {
      isLoading: true,
      config: {},
      version: '',
      versions: [],
      newVersion: '',
      configuration: '',
      configurations: [],
      selectedConfiguration: null,
      newConfigurationName: '',
    }
  },
  methods: {
    async loadConfigurations() {
      await axios.get('/configurations')
        .then((response) => {
          this.configurations = response.data.configurations;
        })
        .catch((error) => {
          console.error('Error fetching configurations:', error);
        });
    },
    loadVersions(configuration) {
      if (!this.selectedConfiguration) {
        this.versions = [];
        return;
      }

      axios.get('/versions?configuration=' + configuration.id, {
      })
        .then((response) => {
          this.versions = response.data.versions;
        })
        .catch((error) => {
          console.error('Error fetching versions:', error);
        });
    },
    addVersion() {
      // Access the Monaco Editor content
      const editorContent = this.$refs.monacoEditor.getEditorContent();
      this.config_data_after_changes = JSON.parse(editorContent);

      const data = {
        configuration_id: this.selectedConfiguration.id,
        version: this.newVersion,
        config: this.config_data_after_changes,
      };

      if (data.config === "") {
        alert('Configuration is empty');
        return;
      }

      axios.post('/manage', data)
        .then((response) => {
          if (response.status === 201) {
            alert('Version added');
            window.location.href = '/';
          } else {
            alert('Error adding configuration');
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            alert('Version already exists');
          } else {
            console.error('Error saving configuration:', error);
            alert('Error saving configuration');
          }
        });
    }
    },
  async beforeMount() {
    this.isLoading = true;
    await this.loadConfigurations();
    this.isLoading = false;
  },
}
</script>

<style scoped>
</style>
